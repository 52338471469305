<template>
  <a
    class="cms-media-link"
    :href="href"
    :target="target"
  >
    <ui-item
      class="ui-clickable"
      :text="text"
      :secondary="secondary"
      :icon="icon"
    ></ui-item>
  </a>
</template>

<script>
import { UiItem } from '@/modules/ui/components';

export default {
  name: 'cms-media-links',
  components: { UiItem },
  props: {
    href: {
      type: String,
      required: false,
      default: '#'
    },

    text: {
      type: String,
      required: false,
      default: 'Sin título'
    },

    secondary: {
      type: String,
      required: false,
      default: ''
    },

    icon: {
      type: String,
      required: false,
      default: 'mdi:open-in-new'
    },

    target: {
      type: String,
      required: false,
      default: '_system'
    }
  }
};
</script>

<style lang="scss">
.cms-media-link {
  display: block;
}
</style>